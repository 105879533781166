import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import './style.css';

class CanvasBaseClass extends React.Component {
	cref = React.createRef();

	static propTypes = {
		classNames: PropTypes.array.isRequired,
		parentRef: PropTypes.object.isRequired,
		onMouseEnter: PropTypes.func,
		onMouseLeave: PropTypes.func,
		onMouseDown: PropTypes.func,
		onMouseUp: PropTypes.func,
	};

	componentDidMount = () => {
		this.initCanvas();
		this.onComponentMount();
	};

	componentDidUpdate = () => {
		this.drawCanvas();
	};

	componentWillUnmount = () => {
		this.onComponentUnmount();
	};

	onComponentMount = () => {
	};
	onComponentUnmount = () => {
	};

	initCanvas = () => {
		window.addEventListener( 'resize', this.resizeCanvas );

		const canvas = this.cref.current;

		canvas.addEventListener( 'mousemove', ( e ) => {
			const boundingRect = canvas.getBoundingClientRect();
			const ctop = boundingRect.top;
			const cleft = boundingRect.left;
			const mouseData = {
				mousePosX: e.clientX - cleft,
				mousePosY: e.clientY - ctop,
			};
			this.setState( mouseData );
			this.onMouseMove( mouseData );
		} );

		canvas.addEventListener( 'mouseenter', ( e ) => {
			const boundingRect = canvas.getBoundingClientRect();
			const ctop = boundingRect.top;
			const cleft = boundingRect.left;

			const mouseData = {
				mousePosX: e.clientX - cleft,
				mousePosY: e.clientY - ctop,
				mouseIn: true,
			};
			this.setState( mouseData );
			this.onMouseEnter( mouseData );
		} );

		canvas.addEventListener( 'mouseleave', ( e ) => {
			this.setState( { mouseIn: false } );
			this.onMouseLeave();
		} );

		canvas.addEventListener( 'mousedown', ( e ) => {
			const boundingRect = canvas.getBoundingClientRect();
			const ctop = boundingRect.top;
			const cleft = boundingRect.left;

			this.setState( { mouseDown: true, } );
			this.onMouseDown( {
				mousePosX: e.clientX - cleft,
				mousePosY: e.clientY - ctop,
			} );
		} );

		canvas.addEventListener( 'mouseup', ( e ) => {
			const boundingRect = canvas.getBoundingClientRect();
			const ctop = boundingRect.top;
			const cleft = boundingRect.left;

			this.setState( { mouseDown: false, } );

			this.onMouseUp( {
				mousePosX: e.clientX - cleft,
				mousePosY: e.clientY - ctop,
			} );
		} );

		canvas.addEventListener( 'click', ( e ) => {
			const boundingRect = canvas.getBoundingClientRect();
			const ctop = boundingRect.top;
			const cleft = boundingRect.left;
			this.onMouseClick( {
				mousePosX: e.clientX - cleft,
				mousePosY: e.clientY - ctop,
			} );
		} );
	};

	onMouseDown = () => {
	};
	onMouseUp = () => {
	};
	onMouseClick = () => {
	};
	onMouseMove = () => {
	};
	onMouseEnter = () => {
	};
	onMouseLeave = () => {
	};

	resizeCanvas = () => {
		const parent = this.props.parentRef.current;

		if ( ! parent ) {
			return;
		}

		const { offsetTop = 0, offsetLeft = 0, offsetRight = 0 } = this.props;

		this.cref.current.height = this.props.parentRef.current.clientHeight - offsetTop;
		this.cref.current.width = this.props.parentRef.current.clientWidth - offsetLeft - offsetRight;

		this.drawCanvas();
	};

	getCanvasContext = () => this.cref.current.getContext( '2d' );
	getCanvasDimensions = () => ( {
		height: this.cref.current.height,
		width: this.cref.current.width,
	} );

	drawCanvas = () => {
	};

	render() {
		const classNames = className( 'MapCanvasComponent', this.props.classNames );
		const { offsetTop = 0, offsetLeft = 0, offsetRight = 0 } = this.props;
		return (
			<canvas
				className={classNames}
				ref={this.cref}
				style={
					{ position: 'absolute', top: offsetTop, left: offsetLeft, right: offsetRight }
				}
			/>
		);
	}
}

export default CanvasBaseClass;
