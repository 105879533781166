import PropTypes from 'prop-types';

import CanvasBase from './canvas-base';

class TimelineBackground extends CanvasBase {

	static defaultProps = {
		data: {
			groups: [],
			items: [],
		},
	};

	static propTypes = {
		...CanvasBase.propTypes,
		rows: PropTypes.number.isRequired,
	};

	// TODO props
	borderColor = 'red';
	backgroundColor = 'black;';
	borderWidth = 1;
	backgroundEvenRow = 'cyan';
	backgroundOddRow = 'yellow';

	drawCanvas = () => {
		const ctx = this.getCanvasContext();
		const cdims = this.getCanvasDimensions();

		// TODO zoom

		const rowsInViewport = this.props.rows;
		const renderSlotHeight = cdims.height / rowsInViewport;

		// TODO make props
		ctx.clearRect( 0, 0, cdims.width, cdims.height );

		let i;

		for ( i = 0; i < rowsInViewport; i ++ ) {
			ctx.fillStyle = i % 2 === 0 ? this.backgroundEvenRow : this.backgroundOddRow;
			ctx.fillRect( 0, i * renderSlotHeight, cdims.width, ( i + 1 ) * renderSlotHeight );
		}

		for ( i = 1; i < rowsInViewport; i ++ ) {
			ctx.moveTo( 0, i * renderSlotHeight );
			ctx.lineTo( cdims.width, i * renderSlotHeight )
		}

		ctx.moveTo( this.props.firstColWidth, 0 );
		ctx.lineTo( this.props.firstColWidth, cdims.height );

		ctx.lineWidth = this.borderWidth;
		ctx.strokeStyle = this.borderColor;
		ctx.stroke();
	};
}

export default TimelineBackground;
