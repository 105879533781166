import React, { Component } from 'react';
import className from "classnames";
import { forEach } from 'lodash';

class TimelineCursor extends Component {
	static defaultState = {
		isCursorActive: false,
		mousePosX: 0,
	};

	eventsMap = {};

	componentDidMount = () => {
		this.eventsMap = {
			'timeline-cursor-current-active': this.handleCursorActiveInactive,
			'timeline-cursor-current-inactive': this.handleCursorActiveInactive,
		};

		forEach( this.eventsMap, ( eventCallback, eventName ) => {
			window.addEventListener( eventName, eventCallback );
		} );
	};

	componentWillUnmount = () => {
		forEach( this.eventsMap, ( eventCallback, eventName ) => {
			window.removeEventListener( eventName, eventCallback );
		} );
	};

	handleCursorActiveInactive = ( event ) => {
		const cursorState = event.detail.isCursorIn;

		this.setState( {
			isCursorActive: event.detail,
		} );
	};

	handleTimelineChange = ( event ) => {

	};

	render() {
		const classNames = className( 'MapComponent', this.props.classNames );
		const { offsetTop = 0, offsetLeft = 0, offsetRight = 0 } = this.props;

		return (
			<div
				style={this.props.style}
			>
				bubble
			</div>
		);
	}
}

export default TimelineCursor;
