/**
 * External dependencies
 */
import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, GroundOverlay } from 'react-google-maps';
import axios from 'axios';
import { uniq, isArray, sortBy } from 'lodash';
import moment from 'moment';

/**
 * Internal dependencies
 */
import CanvasTimeline from '../canvas-timeline';

class MapComponent extends React.Component {
	imageCache = {};

	state = {
		radarStations: [],
		activeStations: [],
		loadingStations: [],
		radarStationImages: {},
		renderedRadarImages: {},
		baseUrl: ( process.env.NODE_ENV === 'development' ? 'https://grad-api.h.bisko.be' : 'https://grad-api.h.bisko.be' ),
	};

	componentDidMount = () => {
		axios.interceptors.request.use( ( config ) => {
			// Do something before request is sent

			config.url = this.state.baseUrl + config.url;

			return config;
		}, ( error ) => {
			// Do something with request error
			console.error( error );
			return Promise.reject( error );
		} );

		this.getStations();
		window.addEventListener( 'timeline-cursor-select-time', this.onTimelineSelect );
		window.addEventListener( 'canvas-timeline-render-item', this.onChangeVisibleImages )

		window.imageCacheThingy = this.imageCache;
	};

	componentWillUnmount = () => {
		window.removeEventListener( 'timeline-cursor-select-time', this.onTimelineSelect );

	};

	onTimelineSelect = ( event ) => {
		this.setState( {
			cursorTime: event.detail,
		}, this.markImagesAsSelected );
	};

	markImagesAsSelected = () => {
		if ( !this.state.cursorTime || !this.state.cursorTime.cursorTime ) {
			return;
		}
		const cursorTime = this.state.cursorTime.cursorTime;
		const maxRange = this.state.cursorTime.maxRange;

		Object.keys( this.state.radarStationImages ).forEach( ( station ) => {
			const imagesList = this.state.radarStationImages[ station ] || [];

			this.setState( ( state ) => ( {
					...state,
					radarStationImages: {
						...state.radarStationImages,
						[ station ]: state.radarStationImages[ station ].map( ( item ) => ( {
							...item,
							isSelected: false
						} ) )
					},
				}
			), () => {

				// Protect against TypeError when an empty array is reduced without initial value.
				if ( !imagesList.length ) {
					return;
				}

				const selectedIndex = imagesList.reduce( ( bestIndex, currentImage, currentImageIndex ) => {

					if ( bestIndex === null ) {
						return currentImageIndex;
					}

					const currentImageTime = currentImage.imageDateTime;
					const currentDiff = Math.abs( moment.duration( cursorTime.diff( currentImageTime ) ).asSeconds() );

					const bestImage = imagesList[ bestIndex ];
					const bestImageTime = bestImage.imageDateTime;
					const bestDiff = Math.abs( moment.duration( cursorTime.diff( bestImageTime ) ).asSeconds() );

					// win!
					if ( currentDiff < bestDiff && currentDiff < maxRange ) {
						return currentImageIndex;
					}
					return bestIndex;
				}, null );

				if ( selectedIndex ) {
					this.setState( state => ( {
						radarStationImages: {
							...state.radarStationImages,
							[ station ]: state.radarStationImages[ station ].map( ( item, idx ) => (
								idx === selectedIndex ? {
									...item,
									isSelected: true,
								} : item
							) )
						}
					} ) );
				}
			} );
		} );
	};

	getStations = () => {
		axios.get( '/api/radar/stations' ).then( ( resp ) => {
			this.setState( {
				radarStations: resp.data,
			}, () => {
				if ( this.state.activeStations.length === 0 ) {
					this.activateRadarStation( Object.keys( this.state.radarStations ) );
				}
			} );
		} ).catch( ( err ) => {
			console.log( err );
		} );
	};

	activateRadarStation = ( station ) => {
		if ( !isArray( station ) ) {
			station = [station];
		}

		this.setState( { activeStations: uniq( [...this.state.activeStations, ...station] ) }, () => {
			this.refreshRadarImages( station )
		} );
	};

	deactivateRadarStation = ( station ) => {
		this.setState( { activeStations: this.state.activeStations.filter( ( activeStation ) => activeStation !== station ) } );
	};

	toggleRadarStation = ( e ) => {
		if ( this.state.activeStations.indexOf( e.target.dataset.key ) !== - 1 ) {
			this.deactivateRadarStation( e.target.dataset.key );
		} else {
			this.activateRadarStation( e.target.dataset.key );
		}
	};

	refreshRadarImages = ( station = null ) => {

		if ( station === null ) {
			station = this.state.activeStations;
		}

		this.setState({
			loadingStations: station,
		});

		Promise.all(
			station.map( ( station ) =>
				// TODO fetch only a subset of the image (i.e. last X hours)
				axios.get( `/api/radar/stations/${station}/images` )
					.then( request => {
						const data = [];
						request.data.forEach( ( radarImage ) => {
							// Prepare the timing and make it proper
							const imageData = {
								...radarImage,
								path: this.state.baseUrl + '/radar_images/' + radarImage.station + '/' + radarImage.imageDateFull.split( 'T' )[ 0 ] + '/' + radarImage.path,
								imageDateTime: moment( radarImage.imageDateFull ),
							};
							data.push( imageData );


							// TODO cache the images. This is causing too many requests on here.
							// const cacheEntry = new Image();
							//
							// cacheEntry.onload = ( ev ) => {
							// 	this.imageCache[ imageData.path ] = cacheEntry;
							// };
							//
							// cacheEntry.src = imageData.path;
						} );

						this.setState( {
							radarStationImages: {
								...this.state.radarStationImages,
								[ station ]: data.sort( ( a, b ) => a.valueOf() - b.valueOf() ),
							},
						}, this.markImagesAsSelected );
					} )
					.catch( ( err ) => {
						console.log( err )
					} )
					.finally(() => {
						this.setState({
							loadingStations: this.state.loadingStations.filter(el => el !== station), // Not loading anymore
						});
					})
			)
		).then( values => {

		} );
	};

	getRenderedImageForStation = ( station ) => {
		const selected = this.state.radarStationImages[ station ].filter( ( val ) => ( val.isSelected ) );

		if ( selected && selected.length ) {
			return selected[ 0 ];
		}

		return null;
	};

	getTimelineImages = () => {
		const result = {};

		Object.keys( this.state.radarStationImages ).forEach( ( station ) => {
			result[ station ] = this.state.radarStationImages[ station ] ? this.state.radarStationImages[ station ] : [];
		} );

		return result;
	};

	getRenderedImages = () => {
		const mapOverlays = [];
		const stationMarkers = [];

		this.state.activeStations.forEach( ( station ) => {
			if ( !this.state.radarStationImages[ station ] || !this.state.radarStationImages[ station ].length ) {
				return;
			}

			stationMarkers.push( {
				...this.state.radarStationImages[ station ].stationCoordinates
			} );

			const renderedImage = this.getRenderedImageForStation( station );
			if ( !renderedImage ) {
				return;
			}
			let { range, imageUnixTime } = renderedImage;

			if ( [300, 150].indexOf( range ) === - 1 ) {
				range = 150;
			}

			const stationConfig = this.state.radarStations[ station.toUpperCase() ];

			const nebounds = stationConfig.renderCoordinates[ range ].ne;
			const swbounds = stationConfig.renderCoordinates[ range ].sw;

			// TODO image appears a bit offset to the North
			mapOverlays.push( {
					overlay: ( <GroundOverlay
						key={renderedImage.path}
						defaultUrl={renderedImage.path.replace( /^\.\//, '/' )}
						defaultBounds={new window.google.maps.LatLngBounds(
							new window.google.maps.LatLng( swbounds.lat, swbounds.lon ),
							new window.google.maps.LatLng( nebounds.lat, nebounds.lon )
						)}
						defaultOpacity={1}
					/> ),
					imageTime: moment.unix(
						parseInt( imageUnixTime, 10 ) / 1000
					).format( 'YYYY-MM-DD HH:mm:ss' ),
					imageStation: station,
				}
			);
		} );

		return mapOverlays;
	};

	getStationsForRender = () => {
		return Object.keys( this.state.radarStations ).map( ( station ) => ( {
			title: station,
			isActive: this.state.activeStations.indexOf( station ) !== - 1,
		} ) );
	};

	onChangeVisibleImages = ( item ) => {
		const image = new Image();
		image.src = item.detail.path;
	};

	render() {
		const mapOverlays = this.getRenderedImages();
		return (
			<div className="MapContainer">
				<GoogleMap
					defaultZoom={8}
					defaultCenter={{ lat: 43.4085, lng: 23.2257 }}
				>
					<Marker position={{ lat: 43.4085, lng: 23.2257 }}/>
					{mapOverlays.map( ( overlay ) => overlay.overlay )}
				</GoogleMap>
				<CanvasTimeline
					dataPoints={this.getTimelineImages()}
					groups={this.getStationsForRender()}
					onToggleStation={this.toggleRadarStation}
					onChangeTimelineImages={this.onChangeVisibleImages}
				/>
			</div>
		);
	}
}

export default withScriptjs( withGoogleMap( MapComponent ) );
