import React from 'react';
import { uniqBy } from 'lodash';

import './style.css';

import TimelineBackground from './timeline-background';
import RowCheckbox from './row-checkbox';
import TimelineData from './timeline-data';
import TimelineCursor from './timeline-cursor';
import TimelineControl from './timeline-control';
import moment from "moment";

class CanvasTimeline extends React.Component {
	cref = React.createRef();

	state = {
		dataToRender: {
			items: [],
			groups: [],
		},
	};

	componentDidMount() {
		// Trigger resize for the canvas elements
		window.dispatchEvent( new Event( 'resize' ) );
		window.addEventListener( 'resize', this.updateRelativeDimensions );
	}

	componentDidUpdate = ( prevProps ) => {
		if ( this.props.dataPoints !== prevProps.dataPoints ) {
			this.precalculateData();
		}
	};

	precalculateData = () => {
		const data = [];

		const groupIds = {};
		this.props.groups.forEach( ( groupData, idx ) => {
			groupIds[ groupData.title ] = idx;
		} );

		Object.keys( this.props.dataPoints ).forEach( ( station, groupIdx ) => {
			const images = uniqBy( this.props.dataPoints[ station ], 'path' ).reverse();

			images.forEach( ( imageData, idx ) => {
				data.push( {
					id: parseInt( `1${groupIdx}00${idx}`, 10 ),
					group: groupIds[ station ],
					content: station,
					path: imageData.path,
					start: imageData.imageDateTime,
					end: imageData.imageDateTime.clone().add(1, 'second'),
					type: 'range',
					isSelected: imageData.isSelected,
				} );
			} );
		} );

		this.setState( {
			dataToRender: {
				items: data,
				groups: this.props.groups,
			}
		} );
	};

	render() {
		const firstColWidth = 100;
		const controlsHeight = 25;
		const rightMargin = 25;
		return (
			<div className="MapTimeline" ref={this.cref}>
				<TimelineBackground
					parentRef={this.cref}
					classNames={[ 'TimelineBackground' ]}
					rows={this.props.groups.length}
					firstColWidth={firstColWidth}
					offsetTop={controlsHeight}
				/>
				<RowCheckbox
					parentRef={this.cref}
					classNames={[ 'TimelineCheckboxes' ]}
					columnWidth={firstColWidth}
					offsetTop={controlsHeight}
					groups={this.props.groups}
					onToggleStation={this.props.onToggleStation}
				/>
				<TimelineData
					parentRef={this.cref}
					classNames={[ 'TimelineData' ]}
					offsetLeft={firstColWidth}
					offsetTop={controlsHeight}
					offsetRight={rightMargin}
					rows={this.props.groups.length}
					data={this.state.dataToRender}
				/>
				<TimelineCursor
					parentRef={this.cref}
					classNames={[ 'TimelineCursor' ]}
					offsetLeft={firstColWidth}
					offsetTop={controlsHeight}
				/>
				<TimelineControl
					parentRef={this.cref}
					classNames={[ 'TimelineControls' ]}
					offsetLeft={firstColWidth}
					offsetTop={controlsHeight}
					offsetRight={rightMargin}
				/>
			</div>
		);
	}
}

export default CanvasTimeline;
