import React, { Component } from 'react';
import className from "classnames";

import TimelineCursorBubble from './timeline-control-cursor-time-bubble';
import { TimelineControlAnimate } from "./timeline-control-animate";

class TimelineCursor extends Component {
	static defaultState = {
		hasClicked: false,
		clickedPosition: 0,
	};

	onMouseClick = ( eventOptions ) => {
		this.setState( {
			hasClicked: true,
			clickedPosition: eventOptions.mousePosX,
		} );

		const selectEvent = new CustomEvent( 'timeline-cursor-select', { detail: eventOptions } );
		window.dispatchEvent( selectEvent );
	};


	render() {
		const classNames = className( 'MapComponent', this.props.classNames );
		const { offsetTop = 0, offsetLeft = 0, offsetRight = 0 } = this.props;

		return (
			<div
				className={classNames}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: offsetRight,
					height: offsetTop,
					background: 'red',
				}}
			>
				<TimelineControlAnimate	/>
				<TimelineCursorBubble
					className="TimelineControls-CursorBubble"
					style={{
						position: 'absolute',
						top: 0,
						left: offsetLeft,
						right: 0,
						height: offsetTop,
						background: 'green',
					}}
				/>
			</div>
		);
	}
}

export default TimelineCursor;
