import React, { Component } from 'react';
import './App.css';

import MapComponent from './components/map-component';

class App extends Component {
	state = {
		windowHeight: 0,
		windowWidth: 0,
	};

	mapContainerRef = React.createRef();

	updateWindowDimensions = () => {
		this.setState( {
			windowHeight: this.mapContainerRef.current.clientHeight,
			windowWidth: this.mapContainerRef.current.clientWidth,
		} );
	};

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener( 'resize', this.updateWindowDimensions );
		window.dispatchEvent( new Event( 'resize' ) );
	};

	render() {
		return (
			<div className="App">
				<header className="App-header">
					<h1 className="App-title">Градушки 2.0</h1>
				</header>
				<div className="App-container" ref={this.mapContainerRef}>
					{this.state.windowHeight && (
						<MapComponent
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCiLiWp5jhpKsZIhCF02AQmLNhDAVyIAOo&v=3.exp&libraries=geometry,drawing,places"
							loadingElement={<div style={{ height: `100%` }}/>}
							containerElement={<div style={{ height: this.state.windowHeight }}/>}
							mapElement={<div style={{ height: `100%` }}/>}
						/>
					)}
				</div>
			</div>
		);
	}
}

export default App;
