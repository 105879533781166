import React from 'react';

import './style.css';
import className from "classnames";

class RowCheckbox extends React.Component {
	cref = React.createRef();

	state = {
		dataToRender: {
			items: [],
			groups: [],
		},
		containerDimensions: {
			width: 0,
			height: 0,
		}
	};

	componentDidMount() {
		// Trigger resize for the canvas elements
		window.dispatchEvent( new Event( 'resize' ) );
		window.addEventListener( 'resize', this.resizeContainer );
	}

	resizeContainer = () => {
		const parent = this.props.parentRef.current;

		if ( ! parent ) {
			return;
		}

		const { offsetTop = 0, offsetLeft = 0, columnWidth = 0 } = this.props;

		this.setState( {
			containerDimensions: {
				width: Math.min( this.props.parentRef.current.clientWidth, columnWidth ) - offsetLeft,
				height: this.props.parentRef.current.clientHeight - offsetTop,
			}
		} );
	};

	render() {
		const classNames = className( 'CheckboxContainer', this.props.classNames );
		const { offsetTop = 0, offsetLeft = 0 } = this.props;

		return (
			<div
				className={classNames}
				ref={this.cref}
				style={{
					top: offsetTop,
					left: offsetLeft,
					width: this.state.containerDimensions.width,
					height: this.state.containerDimensions.height
				}}
			>
				{
					this.props.groups.map( ( group ) => {
						return (
							<div
								key={group.title}
								onClick={this.props.onToggleStation}
								data-key={group.title}
							>
								<input
									type='checkbox'
									checked={group.isActive}
									data-key={group.title}
									onChange={this.props.onToggleStation}
								/>
								{group.title}
							</div>
						);
					} )
				}

			</div>
		);
	}
}

export default RowCheckbox;
