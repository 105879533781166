import CanvasBase from './canvas-base';


class TimelineCursor extends CanvasBase {
	state = {
		hasClicked: false,
		clickedPosition: 0,
	};

	static propTypes = {
		...CanvasBase.propTypes,
	};

	// TODO props
	borderColorClicked = 'black';
	borderColorCurrent = 'blue';
	borderWidth = 1;

	onComponentMount = () => {
		window.addEventListener('timeline-animate-forward-tick', this.automatedAdvanceTick);
	}

	onComponentUnmount = () => {
		window.removeEventListener('timeline-animate-forward-tick', this.automatedAdvanceTick);
	};


	automatedAdvanceTick = (event) => {
		const tickConfiguration = event.detail;

		const cdims = this.getCanvasDimensions();
		const width = cdims.width;

		const pxPerMillisecond = width / 20000; // TODO customizable
		const advanceStep = tickConfiguration.tickFrequency * pxPerMillisecond;
		const newDest = this.state.clickedPosition + advanceStep;

		if (newDest > width) {
			// TODO add option to rewind to the start if the cursor reaches the end.
			window.dispatchEvent(new Event('timeline-animate-forward-tick-outofbounds'));
			return;
		}

		this.onMouseClick({
			mousePosX: parseInt( newDest, 10),
		});
	};

	onMouseEnter = () => {
		const selectEvent = new CustomEvent( 'timeline-cursor-current-active', { detail: true } );
		window.dispatchEvent( selectEvent );
	};
	onMouseLeave = () => {
		const selectEvent = new CustomEvent( 'timeline-cursor-current-inactive', { detail: false } );
		window.dispatchEvent( selectEvent );
	};
	onMouseMove = ( eventOptions ) => {
		const selectEvent = new CustomEvent( 'timeline-cursor-current-move', { detail: eventOptions } );
		window.dispatchEvent( selectEvent );

		if ( this.state.mouseDown ) {
			this.onMouseClick( eventOptions );
		}
	};

	onMouseClick = ( eventOptions ) => {
		this.setState( {
			hasClicked: true,
			clickedPosition: eventOptions.mousePosX,
		} );

		const selectEvent = new CustomEvent( 'timeline-cursor-select', { detail: eventOptions } );
		window.dispatchEvent( selectEvent );
	};

	drawCanvas = () => {
		const ctx = this.getCanvasContext();
		const cdims = this.getCanvasDimensions();

		ctx.clearRect( 0, 0, cdims.width, cdims.height );

		if ( this.state.hasClicked ) {
			// there was something clicked!
			ctx.beginPath();
			ctx.lineWidth = this.borderWidth;
			ctx.strokeStyle = this.borderColorClicked;
			ctx.moveTo( this.state.clickedPosition, 0 );
			ctx.lineTo( this.state.clickedPosition, cdims.height )
			ctx.closePath();
			ctx.stroke();
		}

		if ( this.state.mouseIn ) {
			ctx.beginPath();
			ctx.lineWidth = this.borderWidth;
			ctx.strokeStyle = this.borderColorCurrent;
			ctx.moveTo( this.state.mousePosX, 0 );
			ctx.lineTo( this.state.mousePosX, cdims.height )
			ctx.closePath();
			ctx.stroke();
		}
	};
}

export default TimelineCursor;
