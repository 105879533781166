import React, { Component } from 'react';
import className from "classnames";

import TimelineCursorBubble from './timeline-control-cursor-time-bubble';

export class TimelineControlAnimate extends Component {
	state = {
		isPlaying: false,
		tickFrequency: 300,
	};

	playTimer = null;

	componentDidMount() {
		window.addEventListener( 'timeline-animate-forward-tick-outofbounds', this.togglePlay );
	}

	componentWillUnmount() {
		window.removeEventListener( 'timeline-animate-forward-tick-outofbounds', this.togglePlay );
	}

	rewind = () => {
		window.dispatchEvent( new Event( 'timeline-animate-rewind' ) );
	};

	togglePlay = () => {
		this.setState( { isPlaying: ! this.state.isPlaying }, this.togglePlayTimer )
	};

	togglePlayTimer = () => {
		if (this.state.isPlaying === false) {
			clearInterval(this.playTimer);
		}
		else {
			this.playTimer = setInterval(this.playTick, this.state.tickFrequency);
		}
	};

	playTick = () => {
		window.dispatchEvent( new CustomEvent( 'timeline-animate-forward-tick', {
			detail: {
				tickFrequency: this.state.tickFrequency
			}
		} ) );
	};

	render() {
		const classNames = className( 'MapComponent', this.props.classNames );
		const { offsetTop = 0, offsetLeft = 0, offsetRight = 0 } = this.props;

		return (
				<div
					className="TimelineControls-AnimateControls"
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100px',
						height: '25px',
						background: 'blue',
						display: 'flex',
						justifyContent: 'space-between',
						alignContent: 'center',
						padding: '2px 10px',
					}}
				>
					<span
						onClick={this.rewind}
					>
						⏮
					</span>
					<span
						onClick={this.togglePlay}
					>
						⏯
					</span>
					<span>⏭</span>
				</div>
		);
	}
}
