import PropTypes from 'prop-types';
import { sortBy } from 'lodash';

import CanvasBase from './canvas-base';
import moment from "moment";


class TimelineData extends CanvasBase {
	static defaultProps = {
		data: {
			groups: [],
			items: [],
		},
	};

	static propTypes = {
		...CanvasBase.propTypes,
		data: PropTypes.object.isRequired,
		rows: PropTypes.number.isRequired,
	};

	// TODO props
	borderColor = 'red';
	borderColorSelected = 'black';
	borderWidth = 1;

	onComponentMount = () => {
		window.addEventListener( 'timeline-cursor-select', this.onCursorSelect );
	};

	onComponentUnmount = () => {
		window.removeEventListener( 'timeline-cursor-select', this.onCursorSelect );
	};

	getCanvasTimeConstraints = () => {
		const cdims = this.getCanvasDimensions();

		const currentTime = moment();
		const hoursToShow = 8;

		const minuteLength = cdims.width / ( hoursToShow * 60 );
		const viewportStartTime = moment().subtract( hoursToShow, 'h' );

		return {
			currentTime,
			hoursToShow,
			minuteLength,
			viewportStartTime,
		}
	};

	/**
	 * Converts the cursor position to `moment` time.
	 * @param posEvent
	 *
	 * @returns `moment` time
	 */
	onCursorSelect = ( posEvent ) => {
		const cdims = this.getCanvasDimensions();

		// Convert mouse position to timestamp
		const viewPortTimeData = this.getCanvasTimeConstraints();

		const periodSeconds = moment.duration( ( viewPortTimeData.currentTime.diff( viewPortTimeData.viewportStartTime ) ) ).asSeconds();

		const secondsPerPixel = periodSeconds / cdims.width;

		const cursorTime = ( viewPortTimeData.viewportStartTime.add( secondsPerPixel * posEvent.detail.mousePosX, 'second' ) );

		const rangeInSeconds = ( secondsPerPixel * 20 ); // how many pixels to the side to select

		const options = {
			cursorTime: cursorTime,
			maxRange: rangeInSeconds,
		};

		this.setState( {
			cursorSelected: options,
		} );

		const selectEvent = new CustomEvent( 'timeline-cursor-select-time', { detail: options } );
		window.dispatchEvent( selectEvent );

		return cursorTime;
	};

	drawCanvas = () => {
		const ctx = this.getCanvasContext();
		const cdims = this.getCanvasDimensions();

		ctx.clearRect( 0, 0, cdims.width, cdims.height );
		// TODO zoom

		const { items } = this.props.data;

		const { currentTime, hoursToShow, minuteLength, viewportStartTime } = this.getCanvasTimeConstraints();
		const sortedItems = items.sort( ( a, b ) => a.valueOf() - b.valueOf() );

		const viewPortItems = sortedItems.filter( itemData => itemData.start.isAfter( viewportStartTime ) );
		// TODO zoom

		const rowsInViewport = this.props.rows;
		const rowHeight = cdims.height / rowsInViewport;
		const rowOffset = rowHeight / 2;

		ctx.lineWidth = this.borderWidth;

		const itemLength = 4; // TODO make it dynamic

		viewPortItems.forEach( ( item ) => {
			window.dispatchEvent( new CustomEvent( 'canvas-timeline-render-item', { detail: item } ) );
			ctx.strokeStyle = item.isSelected ? this.borderColorSelected : this.borderColor;
			ctx.fillStyle = item.isSelected ? this.borderColorSelected : this.borderColor;

			const itemRow = item.group;
			const startOffset = ( moment.duration( item.start.diff( viewportStartTime ) ).asMinutes() ) * minuteLength;

			ctx.beginPath();
			ctx.moveTo( parseInt( startOffset, 10 ) + 1, parseInt( rowOffset + ( itemRow * rowHeight ), 10 ) + 1 );
			ctx.arc( parseInt( startOffset, 10 ), parseInt( rowOffset + ( itemRow * rowHeight ), 10 ), itemLength, 0, 2 * Math.PI );
			ctx.fill();
			ctx.closePath();
			ctx.stroke();
		} );


	};
}

export default TimelineData;
